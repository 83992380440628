<template>
    <v-container class="px-0">
        <jumanjiManager
            v-if="isAdmin"
            :game-id="gameId"
            :teams_count="teams_count"
            :users-actives="usersActives"
        />

        <div
            class="mb-4 pb-0"
        >
            <p
                v-if="!isAdmin"
                style="text-align: center"
            >
                <span v-if="team.id">{{ $t('games.game') }}: <strong>{{ gameId }}</strong>, {{ $t('games.team') }}: <strong>{{ team.id }}</strong>, {{ $t('games.gamer') }}: <strong>{{ getUser.name }}</strong></span>

                <span
                    v-else
                    v-text="$t('games.notAvailableForYou')"
                />
            </p>
            <div
                class="d-flex justify-center"
                style="min-height: 400px"
            >
                <v-fab-transition>
                    <v-img
                        v-if="card && card.image"
                        :src="getImagePath(card.image)"
                        height="400px"
                        contain
                    />
                </v-fab-transition>
            </div>
        </div>
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import axiosInstance from "../../../plugins/axios";
import { mapGetters } from "vuex";

export default {
    name: 'JumanjiComponnent',
    components: {
        jumanjiManager: () => import('@/components/games/jumanji/jumanjiManager')
    },
    props: {
        gameId: {
            type: Number,
            required: true
        },
        teams_count: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            urlMain: '/games/jumanji/getMyCurrentState/' + this.gameId,
            card: {
                id: '',
                image: ''
            },
            team: {
                id: ''
            },
            usersActives: {}
        }
    },
    computed: {
        ...mapGetters(['getUser', 'isAdmin'])
    },
    async created() {
        await this.fetchData();

        this.configureSocket();
    },
    destroyed() {
        const gameId = this.gameId;
        const teamId = this.team.id ?? '_';
        const userId = this.getUser.id ?? '_';

        let channelsToLeave = [];
        if (this.isAdmin) {
            channelsToLeave = [
                { channel: `cards.${gameId}._._`, event: 'CardGameSent' },
                { channel: `cards.admin.${gameId}`, events: ['CardGameSent', 'CardTeamSent', 'CardSent'] }
            ];
        } else {
            channelsToLeave = [
                { channel: `cards.${gameId}._._`, event: 'CardGameSent' },
                { channel: `cards.${gameId}.${teamId}._`, event: 'CardTeamSent' },
                { channel: `cards.${gameId}.${teamId}.${userId}`, event: 'CardSent' }
            ];
        }

        for (const channel of channelsToLeave) {
            window.Echo.leave(channel.channel)
        }
    },
    methods: {

        async fetchData() {
            try {
                const data = await axios
                    .get(this.urlMain);

                this.initialize(data.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize(data) {
            this.receiveCard(data.cardActive);
            this.team.id = data.team;
        },
        getImagePath(image) {
            return image ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=' + image : '';
        },
        receiveCard(card) {
            this.card = card;
        },
        setUserState(user, state) {
            this.$set(this.usersActives, user.id + '', state);
        },
        configureSocket() {
            const token = this.$store.getters.getToken;
            window.Echo.connector.pusher.config.auth.headers.authorization = 'Bearer ' + token;
            // window.Echo.connector.pusher.config.auth.headers['Content-Type'] = 'application/vnd.api+json';
            // window.Echo.connector.pusher.config.auth.headers.Accept = 'application/vnd.api+json';

            const gameId = this.gameId;
            const teamId = this.team.id ?? '_';
            const userId = this.getUser.id ?? '_';

            if (this.isAdmin) {
                const channel = { channel: `cards.admin.${gameId}`, events: ['CardGameSent', 'CardTeamSent', 'CardSent'] };
                // listen all the cards on the admin channel
                window.Echo.join(channel.channel)
                    .listen(channel.events[0], (e) => {
                        // console.log(channel.events[0], e)
                        this.receiveCard(e?.card?.card);
                    })
                    .listen(channel.events[1], (e) => {
                        // console.log(channel.events[1], e)
                        this.receiveCard(e?.card?.card);
                    })
                    .listen(channel.events[2], (e) => {
                        // console.log(channel.events[2], e)
                        this.receiveCard(e?.card?.card);
                    });

                const channelPresence = { channel: `cards.${gameId}._._`, event: 'CardGameSent' };
                // listen the users presences on game channel game._._._
                window.Echo.join(channelPresence.channel)
                    .here(users => {
                        // console.log("here ", users)
                        users.forEach(user => { this.setUserState(user, true); });
                    })
                    .joining(user => {
                        // console.log("joining ", user)
                        this.setUserState(user, true);
                    })
                    .leaving(user => {
                        // console.log("leaving ", user)
                        this.setUserState(user, false);
                    })
                    .listenForWhisper('typing', e => {
                        // console.log("listenForWhisper ", e)
                    });
            } else if (this.team.id) {
                const channelsToListen = [
                    { channel: `cards.${gameId}._._`, event: 'CardGameSent' },
                    { channel: `cards.${gameId}.${teamId}._`, event: 'CardTeamSent' },
                    { channel: `cards.${gameId}.${teamId}.${userId}`, event: 'CardSent' }
                ];

                // listen of the 3 channel (Game, team, and user) Example: cards.1.1._
                for (const channel of channelsToListen) {
                    window.Echo.join(channel.channel)
                        .listen(channel.event, (e) => {
                            // console.log(channel.event, e)
                            this.receiveCard(e?.card?.card);
                        });
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
